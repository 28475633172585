import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";

import axiosInstance from "../../axiosInstance";
import { ERROR, LOGIN_MODAL, SUCCESS } from "../../extras/constants";
import { showModal } from "../../redux/actions/modal";
import { addToast } from "../../redux/actions/toasts";

import CreateableDropDown from "../../components/DropDown/CreateableDropDown";
import Input from "../../components/Input/Input";

export default function CreateMatch({ id, threeByThree }) {
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [embedLink, setEmbedLink] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const getTeams = async () => {
    setIsLoading(true);

    let res;
    let obj;
    try {
      if (location.pathname.includes("tournaments") && id) {
        res = await axiosInstance.get(`/tournament/teams/${id}`);
        obj = res?.data?.data?.data.map((team) => {
          return {
            label: team.name,
            value: team.name,
            _id: team._id,
          };
        });
      } else {
        res = await axiosInstance.get("/team/all");
        obj = res.data.data.map((team) => {
          return {
            label: team.name,
            value: team.name,
            _id: team._id,
          };
        });
      }
      setTeams(obj);
    } catch (error) {
      console.log(error);
      toast.error("Could not fetch existing teams.");
    }
    setIsLoading(false);
  };

  const initialValues = {
    teamAName:"",
    teamARefId: "",
    teamBName:"",
    teamBRefId: "",
    tableReferee: "",
    floorReferee1: "",
    floorReferee2: "",
    floorReferee3: "",
    venue: "",
    city: "",
    dateAndTime: "",
    threeByThree: false,
    youtubeLink: "",
    matchType: "",
  };

  const handleSubmit = async (values) => {
    if (!values?.teamARefId) delete values?.teamARefId;
    if (!values?.teamBRefId) delete values?.teamBRefId;

    if (id && location.pathname.includes("tournaments")) {
      values.tournamentId = id;
      values.threeByThree = threeByThree;
    }

    console.log(values);
    setIsBtnLoading(true);
    try {
      const res = await axiosInstance.post(`/match/create`, values);
      queryClient.invalidateQueries("matchesList");
      dispatch(
        addToast({
          kind: SUCCESS,
          msg: "Match Created Successfully!",
        })
      );
      formik.resetForm();
      if (res.status === 201) {
        if (id && location.pathname.includes("tournaments")) {
          // navigate(`/tournaments/basketball/${id}`);
          navigate(`/mymatches/edit/${res?.data?.result?._id}/A`);
        } else {
          const matchType = res?.data?.result?.threeByThree ? "3*3" : "5*5";
          navigate(`/mymatches/edit/${res?.data?.result?._id}/A`);
        }
      }
    } catch (err) {
      if (err?.response?.status === 409) {
        dispatch(
          addToast({
            kindL: ERROR,
            msg: "Team Found. Please use a different Team Name!",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable Create Match!",
          })
        );
      }
    }
    setIsBtnLoading(false);
  };

  const validateYouTubeUrl = (url) => {
    let error;
    if (url) {
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url?.match(regExp);
      if (match && match[2].length === 11) {
        setEmbedLink(
          "https://www.youtube.com/embed/" +
          match[2] +
          "?autoplay=1&enablejsapi=1"
        );
      } else {
        error = "Invalid YT Link";
        setEmbedLink("");
        return error;
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      teamAName:Yup.string().required("Required"),
      teamARefId: Yup.string().optional(),
      teamBName:Yup.string().required("Required"),
      teamBRefId: Yup.string().optional(),
      city: Yup.string().required("Required"),
      venue: Yup.string().required("Required"),
      floorReferee1: Yup.string().required("Required"),
      tableReferee: Yup.string().required("Required"),
      dateAndTime: Yup.string().required("Required"),
      youtubeLink: Yup.string().required("Required"),
      matchType: Yup.string().required("Required"),
      threeByThree: Yup.boolean().required("Required"),
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    getTeams();
  }, [id]);

  return (
    <div className="max-w-screen-xl w-full mx-auto p-4 font-mont mt-4">
      <div className="flex flex-col gap-7">
        <h1 className="text-xl font-bold tracking-wide">
          CREATE A MATCH {id && "IN YOUR TOURNAMENT"}
        </h1>

        {auth.accessToken ? (
          <>
            <div className="justify-center">
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-1"
              >
                <div>
                  <CreateableDropDown
                    id={"teamAName"}
                    name={"teamAName"}
                    placeholder={"Enter First Team Name"}
                    options={teams}
                    handleChange={(newValue) => {
                      console.log("TEam A refId", newValue);
                      formik.setFieldValue("teamAName",newValue?.value)
                      formik.setFieldValue("teamARefId", newValue?._id);
                    }}
                    isError={
                      formik.touched.teamARefId && formik.errors.teamARefId
                    }
                    error={formik.errors.teamARefId}
                    isLoading={isLoading}
                  />
                  <CreateableDropDown
                    id={"teamBName"}
                    name={"teamBName"}
                    placeholder={"Enter Second Team Name"}
                    options={teams}
                    handleChange={(newValue) => {
                      console.log(newValue)
                      formik.setFieldValue("teamBName",newValue?.value)
                      formik.setFieldValue("teamBRefId", newValue?._id);
                    }}
                    isError={
                      formik.touched.teamBName && formik.errors.teamBName
                    }
                    error={formik.errors.teamBName}
                    isLoading={isLoading}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  {!id && !location.pathname.includes("tournaments") && (
                    <div>
                      <div className="flex gap-5 items-center border-2 rounded-xl px-4 py-2">
                        <label className="text-zinc-400 font-medium text-sm">
                          CHOOSE GAME TYPE -
                        </label>
                        <div className=" text-sm flex items-center gap-3">
                          <button
                            type="button"
                            name="gameType"
                            value="3*3"
                            onClick={() =>
                              formik.setFieldValue("threeByThree", true)
                            }
                            className={`rounded-full text-center px-4 py-[2px] font-semibold
                          ${formik.values.threeByThree === true
                                ? "bg-app-orange text-zinc-700"
                                : "bg-white border"
                              }`}
                          >
                            3*3
                          </button>
                          <button
                            type="button"
                            name="gameType"
                            value="5*5"
                            onClick={() =>
                              formik.setFieldValue("threeByThree", false)
                            }
                            className={`rounded-full text-center px-4 py-[2px] font-semibold
                          ${formik.values.threeByThree === false
                                ? "bg-app-orange text-zinc-700"
                                : "bg-white border"
                              }`}
                          >
                            5*5
                          </button>
                        </div>
                      </div>
                      {formik.touched.gameType && formik.errors.gameType && (
                        <p className="text-red-500 text-xs">
                          {formik.errors.gameType}
                        </p>
                      )}
                    </div>
                  )}
                  <Input
                    type="text"
                    name="tableReferee"
                    value={formik.values.tableReferee}
                    placeholder="Table Referee"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={
                      formik.touched.tableReferee && formik.errors.tableReferee
                    }
                    error={formik.errors.tableReferee}
                  />
                  <Input
                    type="text"
                    name="floorReferee1"
                    value={formik.values.floorReferee1}
                    placeholder="Floor Referee 1"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={
                      formik.touched.floorReferee1 &&
                      formik.errors.floorReferee1
                    }
                    error={formik.errors.floorReferee1}
                  />
                  <Input
                    type="text"
                    name="floorReferee2"
                    value={formik.values.floorReferee2}
                    placeholder="Floor Referee 2"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={
                      formik.touched.floorReferee2 &&
                      formik.errors.floorReferee2
                    }
                    error={formik.errors.floorReferee2}
                  />
                  <Input
                    type="text"
                    name="floorReferee3"
                    value={formik.values.floorReferee3}
                    placeholder="Floor Referee 3"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={
                      formik.touched.floorReferee3 &&
                      formik.errors.floorReferee3
                    }
                    error={formik.errors.floorReferee3}
                  />
                  <Input
                    type="text"
                    name="city"
                    value={formik.values.city}
                    placeholder="City"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={formik.touched.city && formik.errors.city}
                    error={formik.errors.city}
                  />
                  <Input
                    type="text"
                    name="venue"
                    value={formik.values.venue}
                    placeholder="Venue"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={formik.touched.venue && formik.errors.venue}
                    error={formik.errors.venue}
                  />
                  <Input
                    type="datetime-local"
                    name="dateAndTime"
                    value={formik.values.dateAndTime}
                    placeholder="Time"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isError={
                      formik.touched.dateAndTime && formik.errors.dateAndTime
                    }
                    error={formik.errors.dateAndTime}
                  />
                  <select
                    name="matchType"
                    id="matchType"
                    value={formik.values.matchType}
                    onChange={formik.handleChange}
                    placeholder="matchType"
                    className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:text-zinc-400 placeholder:font-medium font-medium"
                  >
                    <option value="formik." hidden>
                      MATCH TYPE
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="mixed">Mixed</option>
                  </select>
                  {formik.errors.matchType && (
                    <p className="text-red-500 ml-4">
                      {formik.errors.matchType}
                    </p>
                  )}

                  <Input
                    type="text"
                    name="youtubeLink"
                    value={formik.values.youtubeLink}
                    placeholder="YT Link"
                    onChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                  />
                  {embedLink && (
                    <iframe
                      src={embedLink}
                      title="YT"
                      id="videoObject"
                      type="text/html"
                      className="h-full w-full mt-5"
                    />
                  )}
                </div>
                <button
                  type="submit"
                  className="mt-5 w-full px-4 py-3 text-sm text-zinc-700 font-bold bg-app-orange rounded-xl text-center"
                  disabled={isBtnLoading}
                >
                  CREATE MATCH
                </button>
              </form>
            </div>
          </>
        ) : (
          <>
            <p className="text-center mb-8">
              Please Login before creating/editing a Match
            </p>
            <button
              onClick={() => showModal({ modalType: LOGIN_MODAL })}
              className="m-auto block py-2 px-4 font-medium rounded bg-app-secondary text-white"
            >
              Log In
            </button>
          </>
        )}
      </div>
    </div>
  );
}
