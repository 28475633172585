import React from "react";
import articleImg from "../../assets/articleImage.jpeg";
import postDay from "../../assets/postImage.jpeg";
import ArticleCard from "../../components/ArticleCard";
import PostDayCard from "../../components/PostDayCard";

const ArticleSection = () => {
  return (
    <section className="flex w-full min-h-[100dvh] items-center justify-center">
      <div className="flex items-center justify-center w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-center w-full p-2">
          <div className="flex items-center justify-center w-full flex-col gap-6">
            <div className="flex items-end sm:items-center justify-between sm:flex-row flex-col gap-4 w-full">
              <div className="flex items-start justify-center w-full flex-col gap-2 sm:gap-4">
                <h4 className="font-opsans font-bold uppercase text-sm text-sec">
                  Featured
                </h4>
                <h2 className="font-mont font-extrabold text-4xl">
                  Sports Article
                </h2>
              </div>
              {/* <div className="flex items-start justify-center bg-app-gray w-44 px-2 text-white rounded-full">
                <select className="bg-app-gray py-2 px-2 w-full text-white focus:outline-none rounded-full">
                  <option value="basketball">BasketBall</option>
                  <option value="football">Football</option>
                  <option value="badminton">Badminton</option>
                  <option value="tabletennis">Table Tennis</option>
                  <option value="other">Other</option>
                </select>
              </div> */}
            </div>
            <div className="flex items-start justify-start w-full md:flex-row flex-col gap-8 lg:gap-12">
              <PostDayCard
                image={postDay}
                heading={`Empowering Matches and Tournaments with Score4All`}
                author={`Team Score4All`}
                date={` `}
              />
              <div className="grid md:grid-cols-1 sm:grid-cols-2 grid-cols-1 gap-4 items-start justify-center w-full">
                <ArticleCard
                  image={articleImg}
                  category={`S4A`}
                  heading={`All-Star Basketball Charity Match welcomes Score4All as our official scoring partner!`}
                  author={`Team Score4All`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticleSection;
