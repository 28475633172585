import React from "react";
import { useLocation } from "react-router-dom";
import GameBar from "../../components/GameBar";
// import GameCategories from "../../components/GameCategories";
import ArticleSection from "../../containers/HomePage/ArticleSection";
import MatchesSection from "../../containers/MatchesPage/MatchesSection";
import { gameType } from "../../extras/constants";
import useGetRecentMatch from "../../hooks/api/useGetRecentMatch";

const MatchesPage = ({ data, isLoading }) => {
  const location = useLocation();
  let gameBarData = [];
  for (let [key, value] of Object.entries(gameType)) {
    gameBarData.push({
      title: key,
      link: value[0].link,
    });
  }
  const { dataMatch: recentMatches, isLoadingMatch } = useGetRecentMatch({
    date: "",
    status: "",
  });
  return (
    <main className="flex items-center justify-center w-full flex-col mb-12 min-h-[100dvh]">
      <GameBar data={gameBarData} />
      {/* <GameCategories data={gameType[location.pathname.split("/")[2]]} /> */}
      <MatchesSection data={data} recentMatches={recentMatches} />
      <ArticleSection />
    </main>
  );
};

export default MatchesPage;
