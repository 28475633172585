import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import DatePicker from "../../components/DatePicker";
import MatchesTeamCard from "./MatchesTeamCard";

const MatchesSection = ({ title = "", data, recentMatches }) => {
  const [form, setForm] = React.useState({
    date: new Date().toISOString().split("T")[0],
  });
  console.log(recentMatches);
  const [matches, setMatches] = React.useState([]);
  const inputRef = useRef();

  const handleIconClick = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const filteredFinished = data?.finished.filter(
      (item) => item.dateAndTime.split("T")[0] === form.date
    );
    const filteredLive = data?.live.filter(
      (item) => item.dateAndTime.split("T")[0] === form.date
    );
    const filteredUpcoming = data?.upcoming.filter(
      (item) => item.dateAndTime.split("T")[0] === form.date
    );
    if ((filteredFinished, filteredUpcoming, filteredLive)) {
      setMatches(() => [
        ...filteredFinished,
        ...filteredLive,
        ...filteredUpcoming,
      ]);
    }
  }, [form.date]);

  return (
    <section className="w-full h-full flex items-start justify-start p-2">
      <div className="flex items-center justify-center w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-center flex-col gap-6 w-full py-6">
          <div className="flex items-start justify-between w-full xs:flex-row flex-col">
            <h1 className="text-sm xs:text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold uppercase font-mont text-black">
              {title ? title : "All"} Matches
            </h1>
            <div className="relative flex items-center pointer-events-auto cursor-pointer place-self-end justify-center">
              <CalendarMonthIcon
                className="text-black pointer-events-none absolute right-0 top-0  z-10"
                onClick={handleIconClick}
              />
              <input
                ref={inputRef}
                type="date"
                name="date"
                id="date"
                onChange={handleChange}
                value={form.date}
                className="opacity-0"
              />
            </div>
          </div>
          <div className="flex items-center justify-center gap-4 w-full">
            <DatePicker form={form} setForm={setForm} data={data} />
          </div>
          <div className="flex items-center justify-center gap-4 w-full">
            {recentMatches &&
              recentMatches.length > 0 &&
              recentMatches.map((item) => (
                <div
                  key={item._id}
                  onClick={() =>
                    setForm({
                      date: item._id,
                    })
                  }
                  className="flex items-center rounded bg-app-orange text-white font-bold flex-col px-8 py-2"
                >
                  <p>{item._id}</p>
                  <div className="flex gap-2  items-center justify-center">
                    <p>Total Matches</p>
                    <p>{item.matches.length}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex items-center justify-center w-[95%] flex-col gap-8">
            {matches && matches.length > 0 ? (
              matches.map((item) => (
                <Link
                  to={item._id}
                  key={item._id}
                  className="flex items-center justify-center w-full"
                >
                  <MatchesTeamCard data={item} />
                </Link>
              ))
            ) : (
              <p className="w-full text-center text-xl items-center text-black">
                No matches found for this date
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MatchesSection;
