import React from "react";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";

export default function Games() {
  return (
    <>
      <MatchesTeamCard />
      <MatchesTeamCard />
      <MatchesTeamCard />
      <MatchesTeamCard />
    </>
  );
}
