import React from "react";

const ArticleCard = ({image,category,heading,author,date}) => {
  return (
    <div className="flex items-center justify-center sm:flex-row flex-col gap-2 p-2 rounded-lg w-full hover:bg-[#F5F7F9] group transition-all">
      <div className="flex items-start justify-start w-full">
        <img
          src={image}
          alt={author}
          className="w-auto h-auto sm:h-36 object-cover rounded-md"
        />
      </div>
      <div className="w-full flex items-start justify-start gap-2 flex-col">
        <div className="flex items-start justify-start w-full">
          <p className="text-sec font-opsans font-bold text-xs">{category}</p>
        </div>
        <div className="flex items-start justify-start w-full">
          <h2 className="font-mont font-extrabold text-lg sm:text-sm lg:text-lg group-hover:text-[#53AA51] text-app-gray">
           {heading}
          </h2>
        </div>
        <div className="flex items-start justify-start w-full">
          <p className="flex items-center justify-center font-opsans text-xs sm:text-[10px] lg:text-xs text-app-gray gap-1">
            By {author}<span className="text-sec">.</span>
            <span className="text-sec">{date}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;