export const nodes = [
  {
    id: "1",
    data: { label: "india" },
    position: { x: 0, y: 50 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "2",
    data: { label: "pakistan" },
    position: { x: 0, y: 150 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "3",
    data: { label: "nepal" },
    position: { x: 0, y: 250 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "4",
    data: { label: "bhutan" },
    position: { x: 0, y: 350 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "5",
    data: { label: "india" },
    position: { x: 200, y: 100 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "6",
    data: { label: "nepal" },
    position: { x: 200, y: 300 },
    sourcePosition: "right",
    targetPosition: "left",
  },
  {
    id: "7",
    data: { label: "india" },
    position: { x: 400, y: 200 },
    sourcePosition: "right",
    targetPosition: "left",
  },
];
