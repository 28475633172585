import React from "react";
import PlayerCard from "../../components/profile/PlayerCard";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";

export default function First() {
  return (
    <div className="font-mont flex flex-col gap-12">
      <div className="flex flex-col gap-9">
        <h1 className="font-bold text-lg">PLAYERS</h1>
        <div className="border border-border-gray rounded-2xl flex flex-col gap-5">
          <div className="w-full grid md:grid-cols-2 gap-8 p-5">
            <PlayerCard />
            <PlayerCard />
            <PlayerCard />
            <PlayerCard />
          </div>
        </div>
      </div>
    </div>
  );
}
