import React, { useState } from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

const ButtonComponent = ({ text, selectedQuarter, setSelectedQuarter }) => {
  return (
    <button
      className={`font-bold uppercase text-xs py-2 px-4 rounded-[12px] ${
        selectedQuarter === `${text}` ? " text-app-orange" : " text-black"
      }`}
      onClick={() => setSelectedQuarter(`${text}`)}
    >
      {text}
    </button>
  );
};

const RightPlay = ({ name, event, totalScore }) => {
  const gameTime = new Date(event?.gameTime).toUTCString().split(" ")[4];
  return (
    <div className="flex items-center justify-end w-full p-2 gap-2">
      <div className="w-full md:w-[50%] flex items-center justify-between gap-2 p-2 px-4 border border-[e6e6e6] rounded-[12px]">
        <div className="flex items-center justify-center flex-col">
          <p className="text-sm font-bold">Q{event?.quarter}</p>
          <p className="text-sm font-bold">
            {totalScore?.teamA} - {totalScore?.teamB}
          </p>
          {/* <p className="text-sm font-bold text-app-orange">{gameTime}</p> */}
        </div>
        <div className="flex items-center justify-center gap-2 relative">
          <div className="flex items-end flex-col justify-end gap-2">
            <p className="text-xs font-bold">{event?.scoredBy?.name}</p>
            <p className="text-xs font-medium">
              {event?.eventType === "score"
                ? `Scored points: ${event?.points}`
                : event?.eventType}
            </p>
          </div>

          <div className="flex items-center justify-center">
            <ProfilePhoto
              name={name ? name : "Team B"}
              color="black"
              restClass="h-20 w-20 object-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LeftPlay = ({ name, event, totalScore }) => {
  // Team B
  const gameTime = new Date(event?.gameTime).toUTCString().split(" ")[4];
  const playerName = event?.scoredBy?.name || event?.foulCommittedBy?.name;
  return (
    <div className="flex items-center justify-start w-full p-2 gap-2">
      <div className="w-full md:w-[50%] flex items-center justify-between gap-2 p-2 px-4 border border-[e6e6e6] rounded-[12px]">
        <div className="flex items-center justify-center gap-2 relative">
          <div className="flex items-center justify-center">
            <ProfilePhoto
              name={name ? name : "Team A"}
              color="black"
              restClass="h-20 w-20 object-cover rounded-full"
            />
          </div>
          <div className="flex items-start flex-col justify-start gap-2">
            <p className="text-xs font-bold">{playerName}</p>
            <p className="text-xs font-medium capitalize">
              {event?.eventType === "score"
                ? `Scored points: ${event?.points}`
                : event?.eventType}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center flex-col">
          <p className="text-sm font-bold">Q{event?.quarter}</p>
          <p className="text-sm font-bold">
            {totalScore?.teamA} - {totalScore?.teamB}
          </p>
          {/* <p className="text-sm font-bold text-app-orange">{gameTime}</p> */}
        </div>
      </div>
    </div>
  );
};

const PlayByPlay = ({ data, totalScore }) => {
  const [selectedQuarter, setSelectedQuarter] = useState("Quarter 1");
  const teamA = data?.teamA?.name;
  const teamB = data?.teamB?.name;

  function filterEventsByQuarter(events, quarter) {
    return events?.filter((event) => event?.quarter === quarter);
  }

  let events = data?.events;
  const [quarter1, setQuarter1] = useState(filterEventsByQuarter(events, 1));
  const [quarter2, setQuarter2] = useState(filterEventsByQuarter(events, 2));
  const [quarter3, setQuarter3] = useState(filterEventsByQuarter(events, 3));
  const [quarter4, setQuarter4] = useState(filterEventsByQuarter(events, 4));

  return (
    <div className="flex items-center justify-center flex-col col-span-4 gap-4 p-2 w-full">
      <div className="w-full flex items-center max-w-xl mx-auto justify-center flex-wrap gap-6 p-2 border rounded-[12px]">
        <ButtonComponent
          text="Quarter 1"
          selectedQuarter={selectedQuarter}
          setSelectedQuarter={setSelectedQuarter}
        />
        <ButtonComponent
          text="Quarter 2"
          selectedQuarter={selectedQuarter}
          setSelectedQuarter={setSelectedQuarter}
        />
        <ButtonComponent
          text="Quarter 3"
          selectedQuarter={selectedQuarter}
          setSelectedQuarter={setSelectedQuarter}
        />
        <ButtonComponent
          text="Quarter 4"
          selectedQuarter={selectedQuarter}
          setSelectedQuarter={setSelectedQuarter}
        />
      </div>
      <div className="w-full flex items-center justify-center flex-col gap-4">
        {selectedQuarter === "Quarter 1" &&
          quarter1?.map((event, index) => {
            return event.team === "a" ? (
              <LeftPlay
                key={index}
                event={event}
                name={teamA}
                totalScore={totalScore}
              />
            ) : (
              <RightPlay
                key={index}
                event={event}
                name={teamB}
                totalScore={totalScore}
              />
            );
          })}
        {selectedQuarter === "Quarter 2" &&
          quarter2?.map((event, index) => {
            return event.team === "a" ? (
              <LeftPlay
                key={index}
                event={event}
                name={teamA}
                totalScore={totalScore}
              />
            ) : (
              <RightPlay
                key={index}
                event={event}
                name={teamB}
                totalScore={totalScore}
              />
            );
          })}
        {selectedQuarter === "Quarter 3" &&
          quarter3?.map((event, index) => {
            return event.team === "a" ? (
              <LeftPlay
                key={index}
                event={event}
                name={teamA}
                totalScore={totalScore}
              />
            ) : (
              <RightPlay
                key={index}
                event={event}
                name={teamB}
                totalScore={totalScore}
              />
            );
          })}
        {selectedQuarter === "Quarter 4" &&
          quarter4?.map((event, index) => {
            return event.team === "a" ? (
              <LeftPlay
                key={index}
                event={event}
                name={teamA}
                totalScore={totalScore}
              />
            ) : (
              <RightPlay
                key={index}
                event={event}
                name={teamB}
                totalScore={totalScore}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PlayByPlay;
