import { BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Bar } from "react-chartjs-2";
import { countOfThreePointers, countOfTwoPointers } from "../../extras/utils";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

Chart.register(ChartDataLabels, CategoryScale, LinearScale, BarElement);

const stats = {
  id: 1,
  title: "2 PTS",
  teamA: 40,
  teamB: 60,
};

const Card = ({
  data,
  highPtsA,
  highPtsB,
  chartData,
  chartOptions,
  heading,
}) => {
  return (
    <div className="w-full flex items-center justify-center flex-col rounded-[12px] border border-[#e6e6e6] p-2">
      <div className="flex w-full items-center justify-center gap-4">
        <div className="w-[20%] flex items-center justify-center flex-col gap-4">
          <ProfilePhoto
            name={data?.teamA?.name}
            restClass="h-6 w-6 rounded-full"
          />
        </div>
        <div className="w-[60%] flex items-center justify-center flex-col gap-4">
          <p className="font-mont text-sm font-bold flex uppercase text-center flex-col gap-1">
            {heading}
            <span className="text-xs font-bold">Leader</span>
          </p>
        </div>
        <div className="w-[20%] flex items-center justify-center flex-col gap-4">
          <ProfilePhoto
            name={data?.teamB?.name}
            restClass="h-6 w-6 rounded-full"
          />
        </div>
      </div>
      <div className="w-full flex items-center justify-between  gap-4">
        <div className="w-[20%] flex items-center justify-center flex-col gap-4">
          <ProfilePhoto
            name={highPtsA?.name}
            className="h-8 w-8 object-cover"
          />
          <p className="font-mont text-xs font-bold flex uppercase text-center">
            {highPtsA?.name}
          </p>
        </div>
        <div className="w-[50%] xl:w-[60%] 2xl:w-[55%] flex items-center justify-center">
          <Bar data={chartData} options={chartOptions} />
        </div>
        <div className="w-[20%] flex items-center justify-center flex-col gap-4">
          <ProfilePhoto
            name={highPtsB?.name}
            className="h-8 w-8 object-cover"
          />
          <p className="font-mont text-xs font-bold flex uppercase text-center">
            {highPtsB?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

const BoxScoreCard = ({ data }) => {
  const highPtsA = data?.teamA?.players?.reduce((maxPlayer, currentPlayer) => {
    return currentPlayer.points > maxPlayer.points ? currentPlayer : maxPlayer;
  }, data?.teamA?.players[0]);

  const highPtsB = data?.teamB?.players?.reduce((maxPlayer, currentPlayer) => {
    return currentPlayer.points > maxPlayer.points ? currentPlayer : maxPlayer;
  }, data?.teamB?.players[0]);
  const chartData = {
    labels: [data?.teamA?.name, data?.teamB?.name],
    datasets: [
      {
        backgroundColor: ["#008348", "#5D76A9"],
        borderColor: ["#008348", "#5D76A9"],
        borderWidth: 1,
        hoverBackgroundColor: ["#008348", "#5D76A9"],
        hoverBorderColor: ["#008348", "#5D76A9"],
        data: [highPtsA?.points, highPtsB?.points],
      },
    ],
  };
  const chartData2 = {
    labels: [data?.teamA?.name, data?.teamB?.name],
    datasets: [
      {
        backgroundColor: ["#008348", "#5D76A9"],
        borderColor: ["#008348", "#5D76A9"],
        borderWidth: 1,
        hoverBackgroundColor: ["#008348", "#5D76A9"],
        hoverBorderColor: ["#008348", "#5D76A9"],
        data: [
          countOfTwoPointers(data?.events, "a"),
          countOfTwoPointers(data?.events, "b"),
        ],
      },
    ],
  };

  const chartData3 = {
    labels: [data?.teamA?.name, data?.teamB?.name],
    datasets: [
      {
        backgroundColor: ["#008348", "#5D76A9"],
        borderColor: ["#008348", "#5D76A9"],
        borderWidth: 1,
        hoverBackgroundColor: ["#008348", "#5D76A9"],
        hoverBorderColor: ["#008348", "#5D76A9"],
        data: [
          countOfThreePointers(data?.events, "a"),
          countOfThreePointers(data?.events, "b"),
        ],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        max: Math.max(Number(stats.teamA), Number(stats.teamB)) + 20,
        display: false,
      },
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    },

    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: Math.round,
        font: {
          weight: "bold",
        },
      },
    },
  };

  return (
    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 items-center col-span-3 justify-center w-full px-4 py-4 gap-8 border-b rounded-br-xl rounded-bl-xl border-[#e6e6e6]">
      <Card
        data={data}
        chartData={chartData}
        chartOptions={chartOptions}
        heading={"Total Points"}
        highPtsA={highPtsA}
        highPtsB={highPtsB}
      />
      <Card
        chartData={chartData2}
        chartOptions={chartOptions}
        heading={"2 Pointer"}
        highPtsA={{ points: countOfTwoPointers(data?.events, "a") }}
        highPtsB={{ points: countOfTwoPointers(data?.events, "b") }}
      />
      <Card
        chartData={chartData3}
        chartOptions={chartOptions}
        heading={"3 Pointer"}
        highPtsA={{ points: countOfThreePointers(data?.events, "a") }}
        highPtsB={{ points: countOfThreePointers(data?.events, "b") }}
      />
    </div>
  );
};

export default BoxScoreCard;
