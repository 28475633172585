import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import ProfilePhoto from "../../components/ProfilePhoto/ProfilePhoto";
import { ERROR, SUCCESS } from "../../extras/constants";
import { addToast } from "../../redux/actions/toasts";

const MatchesTeamCard = ({
  data = {},
  isMyMatch,
  quarterScores,
  totalScore,
}) => {
  const { matchId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  let matchData = data;
  const [isInValidMatch, setisInValidMatch] = useState(true);
  const [isFinished, setIsFinished] = React.useState(false);
  const [isLive, setIsLive] = React.useState(false);
  const [formattedDate, setFormattedDate] = React.useState("");
  const [formattedDay, setFormattedDay] = React.useState("");
  const [formattedTime, setFormattedTime] = React.useState("");

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const startMatch = () => {
    setIsLoading(true);
    axiosInstance
      .post("/match/start", {
        matchId: matchData?._id,
      })
      .then((res) => {
        setIsLoading(false);
        queryClient.invalidateQueries(["myMatches", auth.accessToken]);
        queryClient.invalidateQueries("matchesList");
        navigate(`/matchData/${matchData?._id}/update/score`);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Match Started Successfully!",
          })
        );
      })
      .catch((err) => {
        setIsLoading(false);

        dispatch(
          addToast({
            kind: ERROR,
            msg: "Unable Start Match!",
          })
        );
      });
  };

  // useEffect(()

  useEffect(() => {
    if (data.status === "finished") {
      setIsFinished(true);
    } else {
      data.status === "live" ? setIsLive(true) : setIsLive(false);
    }
  }, [data]);

  useEffect(() => {
    if (matchData?.dateAndTime) {
      const parsedDate = new Date(matchData?.dateAndTime);
      const options = { year: "numeric", month: "long", day: "numeric" };
      setFormattedDate(parsedDate.toLocaleDateString("en-US", options));

      const dayOptions = { weekday: "long" };
      setFormattedDay(parsedDate.toLocaleDateString("en-US", dayOptions));

      const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
      setFormattedTime(parsedDate.toLocaleTimeString("en-US", timeOptions));
    }
  }, [matchData?.dateAndTime]);
  return (
    <div className="w-full flex flex-col gap-4 font-mont items-center justify-center px-4 py-2 rounded-[12px] border border-[#E6E6E6]">
      <div className="w-full flex items-center md:flex-row flex-col justify-between gap-4 md:gap-32 lg:gap-56 xl:gap-96 text-xs p-2 border-b border-[#E4E4E4]">
        <div className="w-full flex items-center justify-between gap-2">
          <p className="font-bold">
            Date - <span className="font-normal">{formattedDate}</span>
          </p>
          <p className="font-bold">
            Day - <span className="font-normal">{formattedDay}</span>
          </p>
        </div>
        <div className="w-full flex items-center justify-between gap-2">
          <p className="font-bold text-[#06FF00] capitalize">
            {matchData?.status}
          </p>
          <p className="font-bold">
            Time - <span className="font-normal">{formattedTime}</span>
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center w-full flex-col p-2 gap-6">
        <div className="w-full flex items-center text-xs justify-center">
          <p className="uppercase font-bold">{matchData?.matchType}</p>
        </div>
        <div
          className={`flex items-center justify-center md:flex-row flex-col w-full ${isLive}`}
        >
          <div className="flex items-center justify-center w-full gap-4">
            <ProfilePhoto
              name={data?.teamA?.name ?? "Team A"}
              color="black"
              restClass="w-24 h-24 rounded-full"
            />
            <div className="flex items-center justify-center flex-col gap-2">
              <p className="font-bold text-2xl">{matchData?.teamA?.name}</p>
              {(isFinished || isLive) && (
                <p className="font-bold text-2xl">{matchData?.teamA?.points}</p>
              )}
            </div>
          </div>
          {!isLive ? (
            <div className="flex items-center justify-center">
              <p className="uppercase font-bold text-3xl">
                {matchData?.teamA?.points > matchData?.teamB?.points ? (
                  <ArrowBackIcon className="!w-14 !h-14" />
                ) : matchData?.teamA?.points < matchData?.teamB?.points ? (
                  <ArrowForwardIcon className="!w-14 !h-14" />
                ) : (
                  "VS"
                )}
              </p>
            </div>
          ) : (
            <div className="overflow-x-auto w-full max-w-xl flex items-center justify-center">
              <div
                className={`${
                  matchData?.threeByThree ? "w-1/2" : "w-full"
                } flex items-center justify-center rounded-l-md drop-shadow-xl bg-white`}
              >
                <div className="flex items-end gap-5 lg:gap-x-14">
                  <div>
                    <h1 className="my-2 font-semibold">
                      {matchData?.teamA?.name}
                    </h1>
                    <h1 className="my-1 font-semibold">
                      {matchData?.teamB?.name}
                    </h1>
                  </div>
                  <div className=" flex lg:gap-14 gap-4 items-center">
                    {[1, 2, 3, 4, 5].map((item) => {
                      if (matchData?.threeByThree && item > 1) return;
                      return (
                        <div className="flex flex-col items-center" key={item}>
                          <h1
                            className={`my-2 font-semibold px-2 ${
                              matchData?.currentQuarter === item
                                ? "bg-white"
                                : " "
                            }`}
                          >
                            {item === 5 || matchData?.threeByThree
                              ? "TOT"
                              : "Q" + item}
                          </h1>
                          <h1 className="my-1 font-semibold">
                            {item === 5
                              ? totalScore?.teamA
                              : quarterScores?.teamA[item] ?? 0}
                          </h1>
                          <h1 className="my-1 font-semibold">
                            {item === 5
                              ? totalScore?.teamB
                              : quarterScores?.teamB[item] ?? 0}
                          </h1>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center justify-center w-full gap-4">
            <div className="flex items-center justify-center flex-col gap-2">
              <p className="font-bold text-2xl">{matchData?.teamB?.name}</p>
              {(isFinished || isLive) && (
                <p className="font-bold text-2xl">{matchData?.teamB?.points}</p>
              )}
            </div>

            <ProfilePhoto
              name={data?.teamB?.name ?? "Team B"}
              color="black"
              restClass="w-24 h-24 rounded-full"
            />
          </div>
        </div>
        <div className="w-full flex items-center  text-xs justify-center">
          <p className="uppercase font-bold">
            Location - <span className="font-normal">{matchData?.venue}</span>
          </p>
        </div>
      </div>
      <div className="w-full flex items-center md:flex-row flex-col justify-center pt-2  border-red-500">
        {!matchId ? (
          <div className="w-full flex items-center md:flex-row flex-col border-t border-[#e4e4e4] justify-between p-2 pb-0">
            <div className="w-full flex items-center justify-start">
              <div className="flex items-center justify-start gap-2">
                <ProfilePhoto
                  name={data?.teamA?.name ?? "Team A"}
                  color="black"
                  restClass="w-12 h-12 rounded-full"
                />
                <p className="text-xs">
                  {matchData?.teamA?.captain
                    ? matchData?.teamA?.players.filter(
                        (player) => player._id === matchData?.teamA?.captain
                      )[0]?.name
                    : matchData?.teamA?.players[0]?.name}
                </p>
              </div>
            </div>
            {isMyMatch && !isFinished && (
              <div className="w-full flex items-center justify-start">
                <Link
                  to={`/mymatches/edit/${matchData?._id}/A`}
                  target="_blank"
                  className="text-sm px-2 py-1 bg-black text-white rounded-[4px]"
                >
                  EDIT MATCH
                </Link>
              </div>
            )}
            {isMyMatch && isLive && (
              <div className="w-full flex items-center justify-start">
                <Link
                  to={`/scoring/${matchData?._id}`}
                  className="text-sm px-2 py-1 bg-black text-white rounded-[4px]"
                >
                  UPDATE SCORE
                </Link>
              </div>
            )}

            {isMyMatch && (isFinished || isLive) && (
              <div className="w-full flex items-center justify-start">
                <Link
                  to={`/matches/basketball/${
                    matchData?.threeByThree ? "3*3" : "5*5"
                  }/${matchData?._id}`}
                  className="text-sm px-2 py-1 bg-black text-white rounded-[4px] uppercase"
                >
                  View Match
                </Link>
              </div>
            )}

            {isMyMatch && !isFinished && !isLive && (
              <div className="w-full flex items-center justify-start">
                <button
                  onClick={startMatch}
                  className="text-sm px-2 py-1 bg-black text-white rounded-[4px] uppercase"
                >
                  START MATCH
                </button>
              </div>
            )}
            <div className="w-full flex items-center justify-end">
              <div className="flex items-center justify-start gap-2">
                <p className="text-xs">
                  {matchData?.teamB?.captain
                    ? matchData?.teamB?.players.filter(
                        (player) => player._id === matchData?.teamB?.captain
                      )[0]?.name
                    : matchData?.teamB?.players[0]?.name}
                </p>
                <ProfilePhoto
                  name={data?.teamB?.name ?? "Team B"}
                  color="black"
                  restClass="w-12 h-12 rounded-full"
                />
              </div>
            </div>
          </div>
        ) : isFinished ? (
          <div className="overflow-x-auto border-t border-[#e4e4e4] w-full p-2 flex items-center justify-center">
            <div
              className={`${
                matchData?.threeByThree ? "w-1/2" : "w-full"
              } flex items-center justify-center rounded-l-md  bg-white`}
            >
              <div className="flex items-end gap-5 lg:gap-x-14">
                <div>
                  <h1 className="my-2 font-semibold">
                    {matchData?.teamA?.name}
                  </h1>
                  <h1 className="my-1 font-semibold">
                    {matchData?.teamB?.name}
                  </h1>
                </div>
                <div className=" flex lg:gap-14 gap-4 items-center">
                  {[1, 2, 3, 4, 5].map((item) => {
                    if (matchData?.threeByThree && item > 1) return;
                    return (
                      <div className="flex flex-col items-center" key={item}>
                        <h1
                          className={`my-2 font-semibold px-2 ${
                            matchData?.currentQuarter === item
                              ? "bg-white"
                              : " "
                          }`}
                        >
                          {item === 5 || matchData?.threeByThree
                            ? "TOT"
                            : "Q" + item}
                        </h1>
                        <h1 className="my-1">
                          {item === 5
                            ? totalScore?.teamA
                            : quarterScores?.teamA[item] ?? 0}
                        </h1>
                        <h1 className="my-1">
                          {item === 5
                            ? totalScore?.teamB
                            : quarterScores?.teamB[item] ?? 0}
                        </h1>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MatchesTeamCard;
