import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import axiosInstance from "../../axiosInstance";
import { SUCCESS } from "../../extras/constants";
import { addToast } from "../../redux/actions/toasts";

const ShowTeamGroups = ({ teamGroups }) => {
  return (
    <div className="flex flex-wrap justify-between items-start gap-10">
      {teamGroups.map((team, key) => (
        <div key={key}>
          <h1 className="font-bold text-2xl">{team.name}</h1>
          {team?.teams?.length > 0 ? (
            team?.teams?.map((i, k) => (
              <div className="border p-2 my-2" key={k}>
                <p>{i.name}</p>
              </div>
            ))
          ) : (
            <div>No Team Selected</div>
          )}
        </div>
      ))}
      {/* <div>
        <h1 className="font-bold text-2xl">Group 2</h1>
        {
          teamGroups.group2.length > 0 ? teamGroups.group2.map((i, k) => (
            <div className="border p-2 my-2" key={k}>
              <p>{i.name}</p>
            </div>
          ))
            :
            (
              <div>
                No Team Selected
              </div>
            )
        }
      </div>
      <div>
        <h1 className="font-bold text-2xl">Group 3</h1>
        {
          teamGroups.group3.length > 0 ? teamGroups.group3.map((i, k) => (
            <div className="border p-2 my-2" key={k}>
              <p>{i.name}</p>
            </div>
          ))
            :
            (
              <div>
                No Team Selected
              </div>
            )
        }
      </div>
      <div>
        <h1 className="font-bold text-2xl">Group 4</h1>
        {
          teamGroups.group4.length > 0 ? teamGroups.group4.map((i, k) => (
            <div className="border p-2 my-2" key={k}>
              <p>{i.name}</p>
            </div>
          ))
            :
            (
              <div>
                No Team Selected
              </div>
            )
        }
      </div>
      <div>
        <h1 className="font-bold text-2xl">Group 5</h1>
        {
          teamGroups.group5.length > 0 ? teamGroups.group5.map((i, k) => (
            <div className="border p-2 my-2" key={k}>
              <p>{i.name}</p>
            </div>
          ))
            :
            (
              <div>
                No Team Selected
              </div>
            )
        }
      </div> */}
    </div>
  );
};

export default function Overview({ existingTeams, format, isMyTournament }) {
  console.log(isMyTournament);
  // console.log(format);
  const dispatch = useDispatch();
  const [groups, setGroups] = useState([]);
  const [teamGroups, setTeamGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState({});
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { id } = useParams();

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const handleDeleteSelectedTeam = (team) => {
    const newSelectedTeams = selectedTeams.filter((t) => t._id !== team._id);
    setSelectedTeams(newSelectedTeams);
  };

  const filterFetchGroupTeams = async () => {
    function compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
    try {
      const res = await axiosInstance.get(
        `/tournament/teams/${id}?format=group`
      );
      const data = res?.data?.data?.data.sort(compare);
      setTeamGroups(data);
    } catch (error) {
      console.log("fileterFetchAllGroupsTeam=>", error.response.data.message);
    }
  };

  const fetchAllGroups = async () => {
    try {
      const res = await axiosInstance.get(`/tournament/groups/${id}`);
      //
      console.log("FetchAllgroups=>", res);
      setGroups(res?.data?.data);
      console.log(res?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchAllTeams = async () => {
    try {
      const res = await axiosInstance.get(`/team/all?page=1`);
      const filteredTeams = res?.data?.data.filter((team) => {
        return !existingTeams?.find((t) => t._id === team._id);
      });
      setAllTeams(filteredTeams);
    } catch (error) {
      console.log(error.message);
    }
  };

  const selectTeam = (team) => {
    if (selectedTeams.includes(team)) {
      setSelectedTeams([...selectedTeams.filter((t) => t._id !== team._id)]);
      return;
    }
    setSelectedTeams([...selectedTeams, team]);
  };

  const selectGroup = (teamId, groupId) => {
    setSelectedGroups((prev) => ({ ...prev, [teamId]: groupId }));
  };

  const addTeams = async () => {
    setLoading(true);
    try {
      const teamsId = selectedTeams.map((team) => team._id);
      teamsId.forEach(async (teamId) => {
        const sendingData = {
          teamId,
          format,
        };
        if (format === "group") {
          sendingData.groupId = selectedGroups[teamId];
        }
        try {
          const res = await axiosInstance.post(
            `/tournament/add/team/${id}`,
            sendingData
          );
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Team Added Successfully!",
            })
          );
          queryClient.invalidateQueries("fetchTourData");
          console.log(res.data.message);
          filterFetchGroupTeams();
        } catch (error) {
          console.log(error.response.data.message);
        }
      });
      setSelectedTeams([]);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      handleModal();
    }
  };

  useEffect(() => {
    if (format === "group") {
      fetchAllGroups();
      filterFetchGroupTeams();
    }
  }, [format]);

  useEffect(() => {
    if (existingTeams) {
      fetchAllTeams();
    }
  }, [existingTeams]);

  return (
    <div className="flex flex-col gap-10">
      {/* <div className="flex flex-col gap-5">
        <h1 className="text-lg font-bold uppercase tracking-wide">
          Tournament Standings
        </h1>
        <MatchMaking />
      </div> */}

      <div className="flex flex-col gap-5">
        <div className="flex-1 flex flex-col gap-5">
          <h2 className="text-lg font-bold uppercase tracking-wide">TEAMS</h2>
          {/* <div className="rounded-[12px] border border-[#E6E6E6] flex flex-col">
            {existingTeams && existingTeams?.length > 0 ? (
              existingTeams?.map((team, index) => (
                <div key={index} className="flex flex-col gap-5 p-4">
                  <div className="flex flex-col gap-2">
                    <h3 className="text-sm font-bold">{team.name}</h3>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4">
                <h3 className="text-sm font-bold">No Teams Added</h3>
              </div>
            )}
          </div> */}
          {teamGroups.length > 0 && <ShowTeamGroups teamGroups={teamGroups} />}
          {isMyTournament && (
            <button
              onClick={handleModal}
              className="w-full px-4 py-3 text-sm text-zinc-700 font-bold bg-app-orange rounded-xl text-center"
            >
              ADD
            </button>
          )}
        </div>
        {/* <div className="flex-[2] flex flex-col gap-5">
          <h2 className="text-lg font-bold uppercase tracking-wide">LEADERS</h2>
        </div>
        <div className="flex-1 flex flex-col gap-5">
          <h2 className="text-lg font-bold uppercase tracking-wide">
            ACTIVITY
          </h2>
        </div> */}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 z-30 w-full h-full bg-black bg-opacity-20 flex justify-center items-center p-4">
          <div className="rounded-xl shadow-xl bg-white p-5 relative max-w-screen-sm w-full min-h-64 flex flex-col justify-between gap-5">
            <div className="flex flex-col gap-3">
              <h1 className="text-md font-bold uppercase tracking-wide">
                SELECT TEAMS YOU WANT TO ADD IN THE TOURNAMENT
              </h1>
              <div className="flex flex-col gap-2">
                <select
                  name="team"
                  id="team"
                  onChange={(e) => {
                    selectTeam(allTeams[e.target.value]);
                    document.getElementById("team").value = "";
                  }}
                  className="w-full border-2 rounded-xl py-3 px-4 leading-tight focus:outline-none text-sm placeholder:text-zinc-400 placeholder:font-medium"
                >
                  <option value="">SELECT TEAM</option>
                  {allTeams?.map((team, index) => (
                    <option key={team._id} value={index}>
                      {team.name}
                    </option>
                  ))}
                </select>
                <div
                  className={`${
                    format === "group"
                      ? "flex flex-col gap-1"
                      : "flex flex-wrap gap-3"
                  }`}
                >
                  {selectedTeams &&
                    selectedTeams.length > 0 &&
                    selectedTeams.map((team, index) => {
                      if (format === "group") {
                        return (
                          <div key={index} className="flex flex-col">
                            <div
                              key={index}
                              className="flex justify-between items-center gap-2 bg-[#F1F1F1] rounded-xl p-2"
                            >
                              <h3 className="text-sm font-bold">{team.name}</h3>
                              <div className="flex gap-2">
                                <select
                                  name=""
                                  id=""
                                  onChange={(e) =>
                                    selectGroup(team._id, e.target.value)
                                  }
                                  className="text-sm font-semibold outline-none border border-zinc-300 rounded-md px-2 py-1"
                                >
                                  <option value="">SELECT</option>
                                  {groups &&
                                    groups.length > 0 &&
                                    groups.map((group, index) => (
                                      <option key={index} value={group._id}>
                                        {group.name}
                                      </option>
                                    ))}
                                </select>
                                <button
                                  onClick={() => handleDeleteSelectedTeam(team)}
                                >
                                  <CloseIcon />
                                </button>
                              </div>
                            </div>

                            <div className="grid grid-cols-2 w-full"></div>
                          </div>
                        );
                      } else {
                        <div
                          key={index}
                          className="flex flex-wrap items-center gap-2 text-[13px] bg-app-orange px-3 py-1 rounded-full font-bold text-zinc-700"
                        >
                          <h3 className="">{team.name}</h3>
                          <button
                            onClick={() => handleDeleteSelectedTeam(team)}
                          >
                            <CloseIcon fontSize="small" />
                          </button>
                        </div>;
                      }
                    })}
                </div>
              </div>

              {format && format === "group" && (
                <div className="flex flex-col gap-2"></div>
              )}
            </div>

            <button
              onClick={addTeams}
              disabled={loading}
              className="w-full px-4 py-3 text-sm text-zinc-700 font-bold bg-app-orange rounded-xl text-center disabled:text-zinc-600"
            >
              {loading ? "ADDING..." : "ADD TEAM"}
            </button>

            <button className="absolute top-2 right-2">
              <CloseIcon onClick={handleModal} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
