import React from "react";
import {
  countOfFreeThrows,
  countOfThreePointers,
  countOfTwoPointers,
} from "../../extras/utils";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
import BarChartCard from "./BarChartCard";

const ShootPercent = ({ data }) => {
  const stats = {
    id: 1,
    title: "Points Comparison",
    teamA: data?.teamA?.points,
    teamB: data?.teamB?.points,
  };
  return (
    <div className="w-full h-full col-span-4 flex flex-col font-mont items-center justify-center px-6 gap-4 py-4 rounded-[12px] border border-[#E6E6E6]">
      <div className="w-full flex items-center justify-start border-b border-[#e6e6e6]  p-2">
        <p className="font-bold uppercase text-base">Shoot Percent</p>
      </div>
      <div className="w-full flex items-center justify-center lg:flex-row flex-col gap-6 p-4">
        <div className="w-full flex items-start justify-center flex-col gap-3">
          <div className="flex items-center justify-start w-full gap-6">
            <ProfilePhoto
              name={data?.teamA?.name ?? "Team A"}
              color="black"
              restClass="w-6 h-6 rounded-full"
            />
            <p className="font-bold">Team A</p>
          </div>
          <div className="flex items-center justify-between gap-2 w-full">
            <p className="uppercase font-medium font-mont">2 Points</p>
            <p className="uppercase  font-mont font-bold text-secondaryA">
              {countOfTwoPointers(data?.events, "a")}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2 w-full">
            <p className="uppercase font-medium font-mont">3 Points</p>
            <p className="uppercase font-bold font-mont text-secondaryA">
              {countOfThreePointers(data?.events, "a")}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2 w-full">
            <p className="uppercase font-medium font-mont">Free THROWS</p>
            <p className="uppercase font-bold font-mont text-secondaryA">
              {countOfFreeThrows(data?.events, "a")}
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col gap-2">
          <BarChartCard key={stats.id} heading={stats.title} data={stats} />
        </div>
        <div className="w-full flex items-center justify-center flex-col gap-3">
          <div className="flex items-center justify-end w-full gap-6">
            <ProfilePhoto
              name={data?.teamB?.name ?? "Team B"}
              color="black"
              restClass="w-6 h-6 rounded-full"
            />
            <p className="font-bold">{data?.teamB?.name}</p>
          </div>
          <div className="flex items-center justify-between gap-2 w-full">
            <p className="uppercase  font-mont font-bold text-secondaryB">
              {countOfTwoPointers(data?.events, "b")}
            </p>
            <p className="uppercase font-medium font-mont">2 Points</p>
          </div>
          <div className="flex items-center justify-between gap-2 w-full">
            <p className="uppercase font-bold font-mont text-secondaryB">
              {countOfThreePointers(data?.events, "b")}
            </p>
            <p className="uppercase font-medium font-mont">3 Points</p>
          </div>
          <div className="flex items-center justify-between gap-2 w-full">
            <p className="uppercase font-bold font-mont text-secondaryB">
              {" "}
              {countOfFreeThrows(data?.events, "b")}
            </p>
            <p className="uppercase font-medium font-mont">Free THROWS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShootPercent;
