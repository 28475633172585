import React from "react";
import { useLocation } from "react-router";
import DatePicker from "../../components/DatePicker";
import MatchesTeamCard from "../MatchesPage/MatchesTeamCard";

export default function Games({ data }) {
  const location = useLocation();
  const isMyTournament = location.search.split("=")[1];

  console.log("Games tournament", data.matches);
  const [form, setForm] = React.useState({
    date: new Date().toISOString().split("T")[0],
  });
  return (
    <div className="flex flex-col gap-10">
      <DatePicker form={form} setForm={setForm} />
      {data.matches.length > 0 &&
        data.matches.map((item, key) => (
          <MatchesTeamCard data={item} key={key} isMyMatch={isMyTournament} />
        ))}
    </div>
  );
}
