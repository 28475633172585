import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function DatePicker({ form, setForm, data }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [totalDays, setTotalDays] = useState(0);
  const [match, setMatch] = useState(data);

  useEffect(() => {
    setMatch(() => data);
  }, [data]);

  useEffect(() => {
    setCurrentDate(form.date ? new Date(form.date) : new Date());
  }, [form.date]);

  const handleDateClick = (date) => {
    setForm({ ...form, date });
  };

  const renderMonthDays = () => {
    const selectedMonth = currentDate.getMonth();
    const selectedYear = currentDate.getFullYear();
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

    return Array.from({ length: daysInMonth }, (_, index) => {
      const day = index + 1;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      const formattedMonth =
        selectedMonth < 9 ? `0${selectedMonth + 1}` : `${selectedMonth + 1}`;
      const formattedDate = `${selectedYear}-${formattedMonth}-${formattedDay}`;
      const isValidDate =
        match?.live.some(
          (item) => item.dateAndTime.split("T")[0] === formattedDate
        ) ||
        match?.finished.some(
          (item) => item.dateAndTime.split("T")[0] === formattedDate
        ) ||
        match?.upcoming.some(
          (item) => item.dateAndTime.split("T")[0] === formattedDate
        );

      return (
        <SwiperSlide
          key={formattedDate}
          className={`flex items-center justify-center ${
            isValidDate ? "cursor-pointer" : "cursor-not-allowed !text-gray-500"
          }`}
          onClick={() => isValidDate && handleDateClick(formattedDate)}
        >
          <div
            className={`flex items-center justify-evenly flex-col ${
              form.date === formattedDate
                ? "bg-orange-500 text-white"
                : "bg-white"
            } rounded-[6px] w-28 h-28 p-4`}
          >
            <div className="flex items-center justify-center w-full">
              <h1 className="font-mont font-bold text-xs">
                {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
                  currentDate
                )}
              </h1>
            </div>
            <div className="flex items-center justify-center w-full">
              <p className="font-mont font-bold text-3xl">{day}</p>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="flex items-center justify-center w-full gap-1 xl:gap-2">
      <div className="flex items-center justify-center w-full">
        <ArrowBackIosIcon className="swiper-prev cursor-pointer" />
      </div>
      <div className="flex items-center justify-center w-[82.5%] sm:w-[90%] md:w-[95%] bg-[#F2F2F2]  rounded-[12px] py-4 select-none  gap-4">
        <Swiper
          modules={[Scrollbar, Navigation]}
          className="px-4 w-full flex items-center justify-center gap-4"
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
          slidesPerView={1}
          spaceBetween={20}
          scrollbar={{ draggable: true }}
          centeredSlides={true}
          breakpoints={{
            300: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            425: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 6,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 7,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 8,
              spaceBetween: 20,
            },
            1536: {
              slidesPerView: 9,
              spaceBetween: 30,
            },
          }}
        >
          {renderMonthDays()}
        </Swiper>
      </div>

      <div className="flex items-center justify-center w-full">
        <ArrowForwardIosIcon className="swiper-next cursor-pointer" />
      </div>
    </div>
  );
}
