import React from "react";

export default function Stats() {
  const heading = [
    "MATCH",
    "PTS/40",
    "R/40",
    "A/40",
    "TS%",
    "AST",
    "TO",
    "USG",
    "REBR",
    "RPM",
  ];
  const tableValue = [
    {
      match: "COMING SOON",
      pts: "--",
      r: "--",
      a: "--",
      ts: "--",
      ast: "--",
      to: "--",
      usg: "--",
      rebr: "--",
      rpm: "--",
    },
  ];

  return (
    <>
      <div className="border border-border-gray rounded-xl overflow-x-auto">
        <table className="w-full text-center table-fixed">
          <thead className="bg-app-orange">
            {heading.map((item, index) => (
              <th
                key={index}
                className={`py-3 min-w-[100px] w-[100px]  ${
                  item === "MATCH" && ""
                }`}
              >
                {item}
              </th>
            ))}
          </thead>
          <tbody className="text-sm font-medium text-zinc-700">
            {/* All Values */}
            {tableValue.map((item, index) => (
              <tr key={index}>
                <td className="py-2 border-r last:border-none">{item.match}</td>
                <td className="py-2 border-r last:border-none">{item.pts}</td>
                <td className="py-2 border-r last:border-none">{item.r}</td>
                <td className="py-2 border-r last:border-none">{item.a}</td>
                <td className="py-2 border-r last:border-none">{item.ts}</td>
                <td className="py-2 border-r last:border-none">{item.ast}</td>
                <td className="py-2 border-r last:border-none">{item.to}</td>
                <td className="py-2 border-r last:border-none">{item.usg}</td>
                <td className="py-2 border-r last:border-none">{item.rebr}</td>
                <td className="py-2 border-r last:border-none">{item.rpm}</td>
              </tr>
            ))}

            {/* Total */}
            {/* <tr className="border-t">
              <td className="py-2 font-bold">TOTAL</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
              <td className="py-2">20</td>
            </tr> */}
          </tbody>
        </table>
      </div>

      <div className="px-5">
        <ul className="list-disc text-sm flex flex-col gap-3">
          <li>
            <span className="font-semibold">A/40</span> : Assists Per 40 Minutes
          </li>
          <li>
            <span className="font-semibold">AST</span> : The percentage of a
            team's possessions that ends in an assist. Assist Ratio = (Assists x
            100) divided by [(FGA + (FTA x 0.44) + Assists + Turnovers]
          </li>
          <li>
            <span className="font-semibold">P/40</span> : Points Per 40 Minutes
          </li>
          <li>
            <span className="font-semibold">R/40</span> : Rebounds Per 40
            Minutes
          </li>
          <li>
            <span className="font-semibold">REBR</span> : The percentage of
            missed shots that a team rebounds. Rebound Rate = (Rebounds x Team
            Minutes) divided by [Player Minutes x (Team Rebounds + Opponent
            Rebounds)]
          </li>
          <li>
            <span className="font-semibold">RPM</span> : Real Plus-Minus
          </li>
          <li>
            <span className="font-semibold">TO</span> : The percentage of a
            team's possessions that end in a turnover. Turnover Ratio =
            (Turnover x 100) divided by [(FGA + (FTA x 0.44) + Assists +
            Turnovers]
          </li>
          <li>
            <span className="font-semibold">TS%</span> : What a team's shooting
            percentage would be if we accounted for free throws and 3-pointers.
            True Shooting Percentage = (Total points x 50) divided by [(FGA +
            (FTA x 0.44)]
          </li>
          <li>
            <span className="font-semibold">USG</span> : The number of
            possessions a player uses per 40 minutes. Usage Rate ={" "}
            {
              "{[FGA + (FT Att. x 0.44) + (Ast x 0.33) + TO] x 40 x League Pace}"
            }{" "}
            divided by (Minutes x Team Pace)
          </li>
        </ul>
      </div>
    </>
  );
}
