import React from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modal";
import { LOGIN_MODAL } from "../../extras/constants";
import { Link, Route, Routes, useParams } from "react-router-dom";

import teamProfile from "../../assets/teamProfile.svg";
import First from "../../containers/Profile-team/First";
import Games from "../../containers/Profile-team/Games";
import Stats from "../../containers/Profile-team/Stats";

function TeamSection({ auth, showModal }) {
  const { game } = useParams();
  const pageNavs = [
    {
      name: "PROFILE",
      route: `/profile/${game}/team`,
    },
    {
      name: "GAMES",
      route: `/profile/${game}/team/games`,
    },
    {
      name: "TEAM STATS",
      route: `/profile/${game}/team/stats`,
    },
  ];
  return (
    <div>
      {auth.isLoggedIn ? (
        <div className="py-10 flex flex-col gap-10">
          <div className="flex flex-col gap-10 max-w-screen-xl w-full mx-auto px-3">
            {/* Search Bar */}
            <div>
              <input
                type="text"
                className="text-center w-full border border-border-gray rounded-xl outline-none focus:border-app-orange py-2 px-4 font-semibold text-[16px] placholder:text-sm"
                placeholder="SEARCH PLAYERS"
              />
            </div>

            {/* Hero */}
            <div className="min-h-[380px] w-full">
              <img
                src={teamProfile}
                className="w-full shadow-xl rounded-2xl"
                alt="teamPlayers"
              />
            </div>
          </div>

          {/* in-page navigation */}
          <div className="w-screen  shadow-md overflow-hidden">
            <div className="max-w-screen-xl mx-auto">
              <div className="flex gap-7 items-center font-semibold py-4 font-mont text-[15px] overflow-x-auto md:px-0 px-2">
                {pageNavs.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={item.route}
                      className={`rounded-md px-2 py-1 min-w-max
                    ${
                      item.route === window.location.pathname
                        ? "text-app-orange"
                        : "hover:text-app-orange"
                    }
                  `}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Routes */}
          <div className="max-w-screen-xl w-full mx-auto py-10 -mt-10 overflow-hidden">
            <div className="flex flex-col gap-10 px-3">
              <Routes>
                <Route path="/" element={<First />} />
                <Route path="/games" element={<Games />} />
                <Route path="/stats" element={<Stats />} />
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3">
          <p className="text-center">Please Login to see Player Profiles</p>
          <button
            onClick={() => {
              showModal({
                modalType: LOGIN_MODAL,
              });
            }}
            className="m-auto block py-2 px-4 font-medium rounded bg-app-secondary text-white"
          >
            Log In
          </button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (kind) => dispatch(showModal(kind)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSection);
