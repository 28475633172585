import { Delete } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import { DELETE_CONFIRMATION_MODAL } from "../../extras/constants";
import useMutatePlayerStatus from "../../hooks/api/useMutatePlayerStatus";
import { SHOW_MODAL } from "../../redux/actions/types";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { toast } from "react-toastify";

function EditCardShowPlayers({ team, selectPlayer, setCaptainName }) {
    if (setCaptainName) {
        const capId = team?.captain;

        const captain = team?.players.find((player) => player._id === capId);
        if (captain) {
            setCaptainName(captain?.name);
        } else {
            setCaptainName(null);
        }
        // console.log(captain);
    }

    const viewOnly = false;
    const params = useLocation();
    // console.log(params);
    let headers = [
        !params.pathname.includes("/mymatches/selectPlayer") && "C",
        "Jersey No.",
        "Player",
        selectPlayer ? "Active" : "Delete",
        // !selectPlayer && "Edit",
    ];
    if (params.pathname.includes("/mymatches/selectPlayer")) {
        headers = [
            "Jersey No.",
            "Player",
            selectPlayer ? "Active" : "Delete",
            // !selectPlayer && "Edit",
        ];
    }
    selectPlayer && headers.pop();

    const { matchId, team: paramsTeam } = useParams();

    const { mutate, isLoading } = useMutatePlayerStatus();
    const handlePlayerStatus = (playerId, isActive) => {
        // console.log("playerId", playerId);
        const data = {
            playerId,
            isActive,
            matchId,
            team: paramsTeam.toLowerCase(),
        };
        // console.log("data of player status", data);
        mutate(data);
    };

    const [playersStatus, setPlayersStatus] = useState(
        team?.players.reduce(
            (obj, item) => Object.assign(obj, { [item._id]: item.isActive }),
            {}
        )
    );

    const dispatch = useDispatch();

    const handleCaptainChange = async (playerId) => {
        // console.log("captain changes", playerId);
        try {
            const res = await axiosInstance.post("/match/mark/captain", {
                playerId,
                matchId,
                team: paramsTeam.toLowerCase(),
            });
            // console.log(res);
            toast.success("Player marked as captain");
        } catch (error) {
            // console.log(error);
        }
    };



    return (
        <>
            {team?.players.length > 0 && (
                <table className="w-full border-collapse border my-2 ">
                    <tr>
                        {headers.map((label, index) => (
                            <th
                                key={index}
                                className="text-center  p-2 border-r-2 border-b-2 boder-[#ddd]"
                            >
                                {label}
                            </th>
                        ))}
                    </tr>
                    {team?.players
                        .sort(
                            (a, b) =>
                                a?._id?.jerseyNumber - b?._id?.jerseyNumber
                        )
                        .map((player, index) => (
                            <tr
                                key={index}
                                onClick={() => { }}
                                className={`border-b ${player.isActive && "hover:bg-[#ddd]"
                                    } ${viewOnly ? "" : "cursor-pointer"}`}
                            >
                                {!params.pathname.includes("/mymatches/selectPlayer") && <td className="text-center p-2 border-r-2 border-[#ddd] relative">
                                    <input
                                        onChange={() => {
                                            handleCaptainChange(player?._id);
                                        }}
                                        id="captain"
                                        name="captain"
                                        type="radio"
                                    />
                                </td>}
                                <td className="text-center p-2 border-r-2 border-[#ddd] relative">
                                    {player?.isCaptain && (
                                        <div className="h-full absolute left-2 top-0 flex items-center justify-center">
                                            <span className="bg-yellow-400 text-black font-bold h-7 w-7 flex items-center justify-center rounded-full">
                                                C
                                            </span>
                                        </div>
                                    )}
                                    {player?.jerseyNumber
                                        ? player?.jerseyNumber
                                        : player?._id?.jerseyNumber}{" "}
                                </td>
                                <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                    <Link to={`/profile/${player?._id}`}>
                                        {player?.name}
                                    </Link>
                                </td>
                                {selectPlayer ? (
                                    <td className="text-center p-2 border-r-2 border-[#ddd] ">
                                        {/* <Toggle
                      isActive={player?.isActive}
                      disabled={isLoading}
                      onClick={() => {
                        handlePlayerStatus(player?._id, !player?.isActive);
                      }}
                    /> */}
                                        <Checkbox
                                            checked={player.isActive}
                                            onChange={() => {
                                                handlePlayerStatus(
                                                    player._id,
                                                    !player?.isActive
                                                );
                                            }}
                                        />
                                    </td>
                                ) : (
                                    <td
                                        onClick={() => {
                                            // console.log("Clicked");
                                            dispatch({
                                                type: SHOW_MODAL,
                                                payload: {
                                                    modalType:
                                                        DELETE_CONFIRMATION_MODAL,
                                                    modalTitle:
                                                        "Are you sure you want to delete this player?",
                                                    modalProps: {
                                                        player,
                                                        matchId,
                                                        team: paramsTeam.toLowerCase(),
                                                    },
                                                },
                                            });
                                        }}
                                        className="text-center p-2 border-r-2 border-[#ddd] "
                                    >
                                        <Delete className="text-[#F53535]" />
                                    </td>
                                )}
                                {/* {selectPlayer ? null : (
                <td className="text-center p-2 border-r-2 border-[#ddd] ">
                  <button
                    onClick={() => {
                      dispatch({
                        type: SHOW_MODAL,
                        payload: {
                          modalType: EDIT_PLAYER_MODAL,
                          modalTitle: "Edit Player",
                          modalProps: {
                            player,
                            matchId,
                            team: paramsTeam.toLowerCase(),
                          },
                        },
                      });
                    }}
                    className="text-white px-2 py-1 rounded-lg"
                  >
                    <Edit className="text-gray-500" />
                  </button>
                </td>
              )} */}
                            </tr>
                        ))}
                </table>
            )}
        </>
    );
}

export default EditCardShowPlayers;
