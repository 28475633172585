import React from "react";
import { Link, useLocation } from "react-router-dom";

//Data Expected Structure :  data = [{title: "basketball", link: "/basketball/nba"}]
export default function GameBar({ data }) {
  const location = useLocation();
  return (
    <div className="w-full hidden md:flex bg-app-orange">
      <div className="w-full max-w-screen-xl mx-auto text-white flex justify-center">
        <div className="flex gap-4 md:gap-7 md:justify-center items-center font-semibold font-mont text-[12px] sm:text-xs md:text-[15px] overflow-auto">
          {data.map((item, index) =>
            item?.title === "basketball" ? (
              <Link
                key={index}
                to={item?.link ?? "/"}
                className={`py-4 px-6 ${
                  location.pathname.includes(item?.title?.toLowerCase().trim())
                    ? " text-white bg-black rounded-tl-lg rounded-tr-lg "
                    : " text-white hover:text-[#ffd7b1] transition duration-300"
                } font-bold text-sm font-mont`}
              >
                <p className="uppercase">{item?.title}</p>
              </Link>
            ) : (
              <div
                key={index}
                onClick={() => window.alert("Coming soon! Stay tuned.")}
                className={`py-4 px-6 ${
                  location.pathname.includes(item?.title?.toLowerCase().trim())
                    ? " text-white bg-black rounded-tl-lg rounded-tr-lg "
                    : " text-white hover:text-[#ffd7b1] transition duration-300"
                } font-bold text-sm font-mont`}
              >
                <p className="uppercase">{item?.title}</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
