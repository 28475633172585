import React from "react";
import player from "../../assets/playerCard.svg";

export default function PlayerCard() {
  return (
    <div className="border border-border-gray rounded-2xl p-3 flex flex-col gap-2">
      <h1 className="text-center font-bold text-xl break-words">PLAYER NAME</h1>
      <h2 className="text-center">PLAYER POSITION</h2>
      <div className="flex gap-2 justify-between">
        <div className="flex flex-col gap-3 flex-1">
          <div className="flex flex-col w-full justify-between items-start text-[15px] tracking-wider">
            <h3 className=" font-bold">COUNTRY</h3>
            <h3>HEIGHT</h3>
          </div>
          <div className="flex flex-col w-full justify-between items-start text-[15px] tracking-wider">
            <h3 className=" font-bold">TEAM</h3>
            <h3>BOSTON CELTICS</h3>
          </div>
        </div>

        <div className="flex-1">
          <img src={player} alt="player" />
        </div>

        <div className="flex flex-col gap-3 flex-1">
          <div className="flex flex-col w-full justify-between items-end text-[15px] tracking-wider">
            <h3 className=" font-bold">HEIGHT</h3>
            <h3>6'6</h3>
          </div>
          <div className="flex flex-col w-full justify-between items-end text-[15px] tracking-wider">
            <h3 className=" font-bold">AGE</h3>
            <h3>32</h3>
          </div>
        </div>
      </div>
      <div className="bg-black text-white text-[15px] tracking-wider rounded-xl p-3 flex flex-col gap-2 items-center -mt-2">
        <h1 className="font-bold text-xl">THIS SEASON</h1>
        <div className="flex justify-center items-start flex-wrap gap-5 font-semibold">
          <div className="flex flex-col gap-2 items-center">
            <p className="text-[14px]">PTS/GAME</p>
            <p className="font-bold text-xl">12</p>
          </div>
          <div className="flex flex-col gap-2 items-center">
            <p className="text-[14px]">REBOUNDS/GAME</p>
            <p className="font-bold text-xl">12</p>
          </div>
          <div className="flex flex-col gap-2 items-center">
            <p className="text-[14px]">ASSISTS/GAME</p>
            <p className="font-bold text-xl">12</p>
          </div>
        </div>
      </div>
    </div>
  );
}
