// import React, { useState } from "react";
// import FinishedSection from "../containers/FinishedSection";
import GameBar from "../components/GameBar";
import AboutSection from "../containers/HomePage/AboutSection";
import ArticleSection from "../containers/HomePage/ArticleSection";
import HeroSection from "../containers/HomePage/HeroSection";
import { gameType } from "../extras/constants";
// import LiveSection from "../containers/LiveSection";
// import UpcomingSection from "../containers/UpcomingSection";
// import { MATCH_STATUS } from "../extras/constants";

function HomePage({ apiResponse, isLoading }) {
  // const [showTab, setShowTab] = useState(MATCH_STATUS.UPCOMING);
  let gameBarData = [];
  for (let [key, value] of Object.entries(gameType)) {
    gameBarData.push({
      title: key,
      link: value[0].link,
    });
  }
  return (
    <main className="flex flex-col items-center justify-center">
      {/* <div className="md:hidden">
        <div className="container fixed inset-x-0 mx-auto z-10">
          <div className="w-full  grid grid-cols-3  justify-items-center -mt-6 mb-2  bg-white text-black uppercase">
            <div
              className={`tabs ${showTab === MATCH_STATUS.LIVE ? "tabs-active" : ""
                } `}
              onClick={() => setShowTab(MATCH_STATUS.LIVE)}
            >
              Live
            </div>
            <div
              className={` tabs ${showTab === MATCH_STATUS.UPCOMING ? "tabs-active" : ""
                } `}
              onClick={() => setShowTab(MATCH_STATUS.UPCOMING)}
            >
              Upcoming
            </div>
            <div
              className={` tabs ${showTab === MATCH_STATUS.FINISHED ? "tabs-active" : ""
                } `}
              onClick={() => setShowTab(MATCH_STATUS.FINISHED)}
            >
              Finished
            </div>
          </div>
        </div>
        <div
          className={`mr-0  pb-4 md:pb-12 ${showTab === MATCH_STATUS.LIVE ? "" : "hidden"
            }`}
        >
          <LiveSection data={apiResponse?.live} isLoading={isLoading} />
        </div>
        <div
          className={`mr-0  pb-4 md:pb-12 ${showTab === MATCH_STATUS.UPCOMING ? "" : "hidden"
            } `}
        >
          <UpcomingSection data={apiResponse?.upcoming} isLoading={isLoading} />
        </div>
        <div
          className={`mr-0  pb-4 md:pb-12 ${showTab === MATCH_STATUS.FINISHED ? "" : "hidden"
            }  `}
        >
          <FinishedSection data={apiResponse?.finished} isLoading={isLoading} />
        </div>
      </div>
      <div className="hidden md:block">
        <UpcomingSection data={apiResponse?.upcoming} isLoading={isLoading} />
      </div> */}
      <GameBar data={gameBarData} />
      <section className="flex items-center justify-center w-full border-[#ffbf49] border-t flex-col gap-6 mb-12 min-h-[100dvh]">
        <HeroSection />
        <ArticleSection />
        <AboutSection />
        {/* <PostsSection /> */}
      </section>
    </main>
  );
}

export default HomePage;
