import React from 'react'

const PostDayCard = ({image,heading,author,date}) => {
  return (
    <div className="flex flex-col gap-4 items-start justify-center w-full">
                <div className="flex items-start justify-start w-full">
                  <img
                    src={image}
                    alt="post of tha day"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <div className="flex items-start justify-start w-full">
                  <p className="text-sec font-opsans font-bold text-xs">
                    Post of the Day
                  </p>
                </div>
                <div className="flex items-start justify-start w-full">
                  <h2 className="font-mont font-extrabold text-4xl text-app-gray">
                    {heading}
                  </h2>
                </div>
                <div className="flex items-start justify-start w-full">
                  <p className="flex items-center justify-center font-opsans text-sm text-app-gray gap-1">
                    By {author}<span className="text-sec text-base">.</span>
                    <span className="text-sec">{date}</span>
                  </p>
                </div>
              </div>
  )
}

export default PostDayCard