import React, { useState } from "react";
import {
  countOfOneForPlayer,
  countOfThreeForPlayer,
  countOfTwoForPlayer,
} from "../../extras/utils";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

const ButtonComponent = ({ text, selectedQuarter, setSelectedQuarter }) => {
  return (
    <button
      className={`font-bold uppercase text-xs py-2 px-4 rounded-[12px] ${
        selectedQuarter === `${text}` ? " text-app-orange" : " text-black"
      }`}
      onClick={() => setSelectedQuarter(`${text}`)}
    >
      {text}
    </button>
  );
};

const TeamStatTable = ({ matchData }) => {
  const [teamData, setTeamData] = useState([]);

  const teamA = matchData?.teamA?.players?.map((player) => {
    return {
      jersey: player.jerseyNumber,
      player: player.name,
      min: 0,
      twoPts: countOfTwoForPlayer(matchData?.events, player._id),
      threePts: countOfThreeForPlayer(matchData?.events, player._id),
      ft: countOfOneForPlayer(matchData?.events, player._id),
      points: player?.points,
      fouls: player?.fouls,
    };
  });

  const teamB = matchData?.teamB?.players?.map((player) => {
    return {
      jersey: player.jerseyNumber,
      player: player.name,
      min: 0,
      twoPts: countOfTwoForPlayer(matchData?.events, player._id),
      threePts: countOfThreeForPlayer(matchData?.events, player._id),
      ft: countOfOneForPlayer(matchData?.events, player._id),
      points: player?.points,
      fouls: player?.fouls,
    };
  });

  const totalStat = (team) => {
    if (!team || team.length === 0) return {};
    return team?.reduce((acc, curr) => {
      return {
        min: acc.min + curr.min,
        twoPts: acc.twoPts + curr.twoPts,
        threePts: acc.threePts + curr.threePts,
        ft: acc.ft + curr.ft,
        points: acc.points + curr.points,
        fouls: acc.fouls + curr.fouls,
      };
    });
  };

  const [selectedQuarter, setSelectedQuarter] = useState("Team A");
  return (
    <div className="flex items-center justify-center flex-col col-span-4 gap-4 p-2 w-full">
      <div className="w-full flex items-center max-w-xs mx-auto justify-center flex-wrap gap-6 p-2 border rounded-[12px]">
        <ButtonComponent
          text="Team A"
          selectedQuarter={selectedQuarter}
          setSelectedQuarter={setSelectedQuarter}
        />
        <div className="w-[1px] h-[2rem] rotate-180 bg-[#e6e6e6] rounded-full"></div>
        <ButtonComponent
          text="Team B"
          selectedQuarter={selectedQuarter}
          setSelectedQuarter={setSelectedQuarter}
        />
      </div>
      <div className="flex flex-col items-center justify-center p-2 max-w-5xl mx-auto w-full">
        <div className="flex items-center justify-start w-full bg-[#f3f3f3] gap-6 rounded-tr-[12px] rounded-tl-[12px] p-4">
          <ProfilePhoto
            name={
              selectedQuarter === "Team A"
                ? matchData?.teamA?.name
                : matchData?.teamB?.name
            }
            restClass="h-12 w-12 object-cover rounded-full"
          />
          <p className="uppercase font-mont font-bold text-2xl">Team A</p>
        </div>
        <div className="w-full overflow-x-auto border !rounded-b-[12px] flex items-start justify-start">
          <table className="min-w-full">
            <thead>
              <tr className=" bg-app-orange text-black">
                <th className="py-2 px-4 text-start">#Jrsy</th>
                <th className="py-2 px-4 text-start">Players</th>
                <th className="py-2 px-4 text-start">Min</th>
                {/* <th className="py-2 px-4 text-start">FG</th> */}
                <th className="py-2 px-4 text-start">2pts</th>
                <th className="py-2 px-4 text-start">3pts</th>
                <th className="py-2 px-4 text-start">FT</th>
                {/* <th className="py-2 px-4 text-start">0</th>
                <th className="py-2 px-4 text-start">D</th>
                <th className="py-2 px-4 text-start">Tot</th>
                <th className="py-2 px-4 text-start">AS</th> */}
                <th className="py-2 px-4 text-start">Points</th>
                <th className="py-2 px-4 text-start">Fouls</th>
              </tr>
            </thead>
            <tbody>
              {selectedQuarter === "Team A"
                ? teamA?.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, subIndex) => (
                        <td
                          key={subIndex}
                          className="py-2 px-4 border-r border-[#e6e6e6]"
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))
                : teamB?.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((value, subIndex) => (
                        <td
                          key={subIndex}
                          className="py-2 px-4 border-r border-[#e6e6e6]"
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
            </tbody>
            <tfoot className="border-t border-[#e6e6e6]">
              <tr>
                <td colspan="2" className="py-2 px-4 border-r border-[#e6e6e6]">
                  {"Total Stats"}
                </td>
                {Object.values(
                  totalStat(selectedQuarter === "Team A" ? teamA : teamB)
                ).map((value, subIndex) => (
                  <td
                    key={subIndex}
                    className="py-2 px-4 border-r border-[#e6e6e6]"
                  >
                    {value}
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TeamStatTable;
