import React from "react";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";
import LineChartCard from "./LineChartCard";

const Comparison = ({ heading, value1, value2 }) => {
  return (
    <div className="w-full flex items-center justify-between">
      <p className="font-medium uppercase text-base">{heading}</p>
      <div className="flex items-center justify-center gap-6">
        <p className="font-bold uppercase text-base text-secondaryA">
          {value1}
        </p>
        <p className="font-bold uppercase text-base text-secondaryB">
          {value2}
        </p>
      </div>
    </div>
  );
};

const GameEvolutionComparison = ({ data, teamAName, teamBName }) => {
  // const teamData = [
  //   { teamA: 50, teamB: 60 },
  //   { teamA: 55, teamB: 58 },
  //   { teamA: 45, teamB: 70 },
  // ];
  let teamData = [
    { teamA: 0, teamB: 0 },
    { teamA: data?.teamA[1], teamB: data?.teamB[1] },
    {
      teamA: data?.teamA[2] + data?.teamA[1],
      teamB: data?.teamB[2] + data?.teamB[1],
    },
    {
      teamA: data?.teamA[3] + data?.teamA[2] + data?.teamA[1],
      teamB: data?.teamB[3] + data?.teamB[2] + data?.teamB[1],
    },
    {
      teamA: data?.teamA[4] + data?.teamA[3] + data?.teamA[2] + data?.teamA[1],
      teamB: data?.teamB[4] + data?.teamB[3] + data?.teamB[2] + data?.teamB[1],
    },
  ];
  return (
    <div className="w-full h-full col-span-4 flex flex-col font-mont items-center justify-center px-6 gap-4 py-4 rounded-[12px] border border-[#E6E6E6]">
      <div className="flex items-center justify-center w-full lg:flex-row flex-col gap-2 ">
        <div className="w-fit h-full flex flex-col font-mont items-center col-span-2 justify-center px-2 py-2 border-b lg:border-r lg:border-b-0 border-[#e6e6e6]">
          <div className="w-full flex items-center justify-start p-2 border-b border-[#E4E4E4]">
            <p className="font-bold uppercase text-base">Game Evolution</p>
          </div>
          <div className="w-full flex items-center justify-start gap-8 py-4 px-2">
            <div className="flex items-center justify-center gap-6">
              <div className="flex items-center justify-center relative">
                <div className="h-[8px] w-[3rem] bg-secondaryA"></div>
                <ProfilePhoto
                  name={teamAName ?? "Team A"}
                  color="black"
                  restClass="w-5 h-5 rounded-full absolute -right-2"
                />
              </div>
              <p className="font-medium text-xs">Team A</p>
            </div>
            <div className="flex items-center justify-center gap-6">
              <div className="flex items-center justify-center relative">
                <div className="h-[8px] w-[3rem] bg-secondaryB"></div>
                <ProfilePhoto
                  name={teamBName ?? "Team A"}
                  color="black"
                  restClass="w-5 h-5 rounded-full absolute -right-2"
                />
              </div>
              <p className="font-medium text-xs">Team B</p>
            </div>
          </div>
          <div className="flex items-center justify-start p-2 flex-col gap-1 w-full">
            <LineChartCard teamData={teamData} />
          </div>
        </div>
        {/* <div className="w-full h-full flex flex-col font-mont items-center col-span-2 justify-center px-2 py-2">
          <div className="w-full flex items-center justify-between p-2">
            <p className="font-bold uppercase text-base">Comparisons</p>
            <div className="flex items-center justify-center gap-6">
              <ProfilePhoto
                name={teamAName ?? "Team A"}
                color="black"
                restClass="w-6 h-6 rounded-full"
              />
              <ProfilePhoto
                name={teamBName ?? "Team B"}
                color="black"
                restClass="w-6 h-6 rounded-full"
              />
            </div>
          </div>
          <div className="w-full flex items-center flex-col justify-start gap-5 py-4 px-2">
            <Comparison heading="2 Points" value1="55%" value2="55%" />
            <Comparison heading="3 Points" value1="30%" value2="30%" />
            <Comparison heading="Free throws" value1="66%" value2="66%" />
            <Comparison heading="2 Points" value1="55%" value2="55%" />
            <Comparison heading="3 Points" value1="30%" value2="30%" />
            <Comparison heading="Free throws" value1="66%" value2="66%" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default GameEvolutionComparison;
